import React from 'react';
import usePartialReload from '@hooks/usePartialReload';

import { scopedTranslation } from '@utils/I18n';
import { Table } from '@components/molecules/Table';

import { ShiftCard } from './ShiftCard';
import { AddShiftDialog } from '../add_shifts/AddShiftDialog';
import { Event, Shift } from '../shared/types';

const sortShifts = (a: Shift, b: Shift): number => {
  if (a.startTime.getTime() !== b.startTime.getTime()) {
    return a.startTime.getTime() - b.startTime.getTime();
  }
  
  if (a.endTime.getTime() !== b.endTime.getTime()) {
    return a.endTime.getTime() - b.endTime.getTime();
  }
  
  return a.roleName.localeCompare(b.roleName);
};

export const ShiftsTable: React.FC<{ event: Event }> = ({ event }) => {
  const handleShiftUpdate = () => { usePartialReload('', ['event'], () => {}) };

  const shifts = event.roster?.shifts?.nodes || [];

  const dateString = new Date(event.startTime).toLocaleDateString();
  const t = scopedTranslation('events_management.shifts');

  return (
    <Table.Root>
      <Table.Header>
        <Table.Row>
          <Table.Cell>{dateString} {t('shifts')}</Table.Cell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {[...shifts].sort(sortShifts).map(shift => (
          <Table.Row key={shift.id}>
            <Table.Cell>
              <ShiftCard shift={shift} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell>
            <AddShiftDialog event={event} onShiftAdded={handleShiftUpdate} />
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table.Root>
  );
};
