import React from 'react';

import { TextInput } from '@atoms';

import { scopedTranslation } from '@frontend/utils/I18n';

type AddShiftFieldRowProps = {
  register: any;
  errors: any;
}
  
export const AddShiftFieldRow: React.FC<AddShiftFieldRowProps> = ({ register, errors }) => {
  const t = scopedTranslation('events_management.shifts');

  return (
    <div className="tw-flex tw-items-start tw-gap-4">
      <TextInput
        displayName={t('start_time')}
        {...register(`startTime`)}
        type='time'
        errors={errors}
      />
      <TextInput
        displayName={t('end_time')}
        {...register(`endTime`)}
        type='time'
        errors={errors}
      />
      <TextInput
        displayName={t('volunteers_required')}
        {...register(`volunteersRequiredCount`)}
        type='number'
        errors={errors}
      />
      <TextInput
        displayName={t('role')}
        {...register(`roleName`)}
        type='text'
        errors={errors}
      />
    </div>
  );
};
