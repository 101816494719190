import React from 'react'

import { formatTime } from '@utils/formatTime';

import { Shift } from '../shared/types';



type ShiftCardProps = {
  shift: Shift
}

export const ShiftCard: React.FC<ShiftCardProps> = ({ shift }) => {
  const shiftStartTime = formatTime(shift.startTime);
  const shiftEndTime = formatTime(shift.endTime);
  return (
    <p>
      {shift.roleName}, {shiftStartTime} - {shiftEndTime}
    </p>
  );
}
