import React from 'react';

import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import useFormWithErrors from '@frontend/hooks/useFormWithErrors';

import { Dialog, Button } from '@atoms';
import { scopedTranslation } from '@utils/I18n';
import { formatTime } from '@utils/formatTime';

import { AddShiftFieldRow } from './AddShiftFieldRow';
import { useAddShiftMutation } from './useAddShiftMutation';
import { Event } from '../shared/types';

type AddShiftDialogProps = {
  event: Event;
  onShiftAdded: () => void;
}

const t = scopedTranslation('events_management.shifts');

const shiftSchema = yup.object({
  startTime: yup.string().required(t('validation.start_time_required')),
  endTime: yup.string().required(t('validation.end_time_required')),
  roleName: yup.string().required(t('validation.role_required')),
  volunteersRequiredCount: yup
    .number()
    .typeError(t('validation.volunteers_required_required')),
});

export const AddShiftDialog: React.FC<AddShiftDialogProps> = ({ event, onShiftAdded }) => {
  const { id: eventId, startTime: eventStartTime, endTime: eventEndTime } = event;

  const [open, setOpen] = React.useState(false);

  const onSuccess = React.useCallback(() => {
    setOpen(false);
    onShiftAdded();
  }, [onShiftAdded]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setErrors: setFormErrors,
  } = useFormWithErrors({
    resolver: yupResolver(shiftSchema),
    mode: 'onSubmit',
    defaultValues: {
      startTime: formatTime(eventStartTime),
      endTime: formatTime(eventEndTime),
      volunteersRequiredCount: 1,
      roleName: ''
    }
  });

  const { addShift } = useAddShiftMutation(eventId, eventStartTime, eventEndTime);

  const onSubmit = async (
    data: {
      startTime: string;
      endTime: string;
      roleName: string,
      volunteersRequiredCount: number
    }
  ) => {
    await addShift(data, onSuccess, setFormErrors);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild={true}>
        <Button>{t('add_shift')}</Button>
      </Dialog.Trigger>
      <Dialog.Content size="3xl">
        <Dialog.Header>
          <Dialog.Title>{t('add_shift')}</Dialog.Title>
        </Dialog.Header>
        <form onSubmit={handleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-4">
          <AddShiftFieldRow register={register} errors={errors} />
          <Dialog.Footer>
            <Dialog.Close> {t('cancel')} </Dialog.Close>
            <Button type="submit" trailingIcon="arrow-circle-broken-right">
              {t('submit_shift')}
            </Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
};
